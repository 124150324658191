/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/activate.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Utils from "../../../utils"
import Keys from '../../../constants/keys'
import { navigate } from "gatsby"

class Activate extends React.Component {
  constructor() {
    super();
    this.state = {
      resendMessage: "",
      passwordMessage: "",
      activateMessage: "",
      termsMessage: "",
    }
  }
  componentDidMount() {
    this.setState({activation: Utils.getQueryVariable(location, "c")})
  }

  render () {
    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state)
    }
    const handleChangeUpper = (e) => {
      let state = {};
      state[e.target.name] = e.target.value.toUpperCase();
      this.setState(state)
    }
    const handleResend = (e) => {
      this.setState({message: "Resending Activation Code"})
      let location = this.props.location
      e.preventDefault();
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'resend_activation'}).then((recaptchaToken) => {
        return fetch(`/api/activate/resend/${Utils.getQueryVariable(location, "a")}`, {
          method: "POST",
          body: JSON.stringify({
            "recaptcha_token": recaptchaToken,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        })
      }).then(function(response) { return response.json() }).then((data) => {
        if(!data.ok) {
          this.setState({resendMessage: "An error occurred sending your activation code"})
        } else {
          this.setState({resendMessage: "Resent Activation Code"})
        }
      })
    }
    const handleActivate = (e) => {
      this.setState({
        resendMessage: "",
        passwordMessage: "",
        activateMessage: "",
        termsMessage: "",
      })
      let target = e.target
      console.log(target);
      e.preventDefault()
      if(target.elements.password.value.length < 8) {
        this.setState({passwordMessage: "Your password must be at least 8 characters"})
        return
      } else if (target.elements.password.value != target.elements.confirm_password.value) {
        this.setState({passwordMessage: "Passwords do not match"})
        return
      } else if (target.elements.activation.value.length != 6) {
        this.setState({activateMessage: "Invalid activation code. Double check your e-mail and try again."})
        return
      } else if(!target.elements.terms.checked) {
        this.setState({termsMessage: "Please Accept the Terms of Use"})
        return
      } else {
        this.setState({activateMessage: "Activating"})
      }

      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'activate'}).then((recaptchaToken) => {
        return fetch(`/api/activate/${Utils.getQueryVariable(location, "a")}`, {
          method: "POST",
          body: JSON.stringify({
              "recaptcha_token": recaptchaToken,
              "activation_code": target.elements.activation.value,
              "password": target.elements.password.value,
              "terms": target.elements.terms.checked,
              "promo_code": target.elements.promo.value,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        });
      }).then(function(response) { return response.json() }).then((data) => {
        console.log(data);
        if(data.ok) {
          if(typeof(dataLayer) == 'object') {
            dataLayer.push({'event': 'signup-complete'});
          }
          window.location = "/dashboard/";
        } else {
          if(data.error == "bad-activation-code") {
            this.setState({activateMessage: "Invalid activation code. Double check your e-mail and try again."});
          } else if (data.error == "bad-promo") {
            this.setState({activateMessage: "The promo code you entered is not available. Double check and try again."});
          } else {
            this.setState({activateMessage: "An unknown error has occurred. Please try again."});
          }
        }
      });
    }
    return (
      <section className={styles.activate}>
      <span className={styles.modalHeader}><Title title="step 3 of 3:" subtitle="Activate your account." /></span>
      <p className={styles.instructionsModal}>Check your email for your activation code and enter it below. By checking the box below, you are agreeing to our <AniLink fade to='/'>Terms of Use</AniLink>.</p>
      <p className={styles.message}>{this.state.termsMessage}</p>
      <div className={styles.center}>
      <form className={styles.form} onSubmit={handleActivate}>
      <div className={styles.row2}>
          <input
          type="checkbox"
          name="terms"
          id="terms"
          className={styles.termsTick}
          height='20px'
          width='20px'
          /><label htmlFor="terms" className={styles.termsLabel}>I agree to Rivet&apos;s&nbsp;
            <AniLink fade to="/terms/" target="_blank" className={styles.instructions}>Terms of Use</AniLink>
          </label>
      </div>
      <article className={styles.appContainer}>
      <div className={styles.row1}>
      <div className={styles.activateText}>
      <input
      type="text"
      name="activation"
      id="activation"
      placeholder="Activation Code"
      value={this.state.activation}
      onChange={handleChange}
      className={styles.activateText}
      />
      </div>
      </div>
      <p className={styles.message}>{this.state.activateMessage}</p>
      <div className={styles.row3}>
        <div className={styles.activateText}><input
          type="password"
          name="password"
          id="password"
          placeholder="Choose a password."
          className={styles.activateText}
        /></div>
        <div className={styles.activateText}><input
          type="password"
          name="confirm_password"
          id="confirm_password"
          placeholder="Re-enter your password."
          className={styles.activateText}
        /></div>
      </div>
      <p className={styles.message}>{this.state.passwordMessage}</p>
      <div className={styles.row3}>
      <div className={styles.activateText}>
        <input
        type="text"
        name="promo"
        id="promo"
        placeholder="Promo Code (if you have one)"
        value={this.state.promo}
        onChange={handleChangeUpper}
        className={styles.activateText}
        />
        </div>
      </div>
      </article>
      <div className={styles.arrowButton}>
      <input
      type="submit"
      value="submit"
      className={styles.submit}
      action="/activated"
      />
      </div>
      <button className={styles.resend} onClick={handleResend}>Resend Email</button>
      <div className={styles.message}>{this.state.resendMessage}</div>
      </form>
      </div>
      <p className={styles.instructionsReminder}><br />No email? Check your junk mail folder for a message from <span className={styles.emphasis}>noreply@rivet.cloud</span>. Email us at <a href="mailto:support@rivet.cloud" target="_blank">support@rivet.cloud</a> if you didn't recieve it.</p>
      </section>
    )
    // TODO: Greg to review language above DONE
  }
}

export default Activate
