/* eslint-disable */
import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import StyledHero from "../components/StyledHero"
import Activate from "../components/Home/Signup/Activate"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
const activate = ({ data, location }) => {
  return (
    <Layout>
      <Helmet>
        <title>Rivet | Signup | Activate</title>
      </Helmet>
      <LoadRecaptcha />
      <StyledHero img="none">
        < Activate location={location} />
      </StyledHero>
    </Layout>
  )
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default activate
